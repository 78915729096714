// images
import AVA_SMALL from '../images/avatar/ava_small.webp';
import AVA_BIG_1 from '../images/avatar/ava _big_1.webp';

export const avatar = AVA_SMALL;
export const firstPhoto = AVA_BIG_1;

// scroll speed
// маньше - быстрее
export const SCROLL_DURATION = 1000;
